<template>
  <div>
    <PublicHeader />
    <div class="banner main">
      <div class="container">
        <v-breadcrumbs :items="breadCrumb" class="main-breadcrumb">
          <template v-slot:divider>
            <img src="@/assets/icons/breadcrumb-devider.svg" alt="bread crumb" />
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <main>
        <div class="container news-more-wrapper">
            <div class="news-more-wrapper-content">
                <div class="news-more-wrapper-content-title">
                    <h4>{{ NewsData.title }}</h4>
                </div>
                <div class="ql-editor" v-html="NewsData.content"></div>
            </div>
        </div>
    </main>
    <HomeFooter />
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
import Api from '@/api/Api';

export default {
  name: 'NewsMoreView',
  components: {
    PublicHeader,
    HomeFooter,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('main'),
          disabled: false,
          link: true,
          exact: true,
          to: {
            path: '/',
          },
        },
        {
          text: this.$t('adsPage'),
          disabled: true,
          to: {
            path: '/',
          },
        },
      ],
      NewsData: [],
    };
  },
  methods: {
    async getNewData() {
      const { data } = await Api.getNewData(this.$route.params.id);
      this.NewsData = data;
    },
  },
  mounted() {
    this.getNewData();
  },
};
</script>

<style src="./NewsMoreView.scss" lang="scss"></style>
